import '@bindings/clipboard'
//import '@bindings/compassBinding'
import '@bindings/filter'
import '@bindings/googleMap'
import '@bindings/hover'
import '@bindings/notification'
import '@bindings/preventSwipeTap'
import '@bindings/rangeSlider'
import '@bindings/scrollBar'
import '@bindings/toggleFilter'
import '@bindings/viewportWidth'
