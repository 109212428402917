/**
 * @file Data Model
 */

import axios from 'axios'

/**
 * @type {Object}
 */
const dataModel = {};

/**
 * Places Array
 * @type {Array.<Object>}
 */
dataModel.places = [
	{

		/**
		 * Place Name
		 * @type {String}
		 */
		name: 'Airports',

		/**
		 * Place Description
		 * @type {String}
		 */
		description: 'description',

		/**
		 * Place Type. Set to a supported value for the types property in the Google Places API
		 * @type {String}
		 * @see {@link https://developers.google.com/places/supported_types}
		 */
		type: 'airport',

		/**
		 * Place Icon. Set to a Font Awesome CSS class.
		 * @type {String}
		 * @see {@link https://fortawesome.github.io/Font-Awesome/icons/}
		 */
		icon: 'fa-plane',

		/**
		 * Marker Icon. Set to Font Awesome SVG marker.
		 * @type {Object}
		 */
		marker: {
			path: 'M480 192H365.71L260.61 8.06A16.014 16.014 0 0 0 246.71 0h-65.5c-10.63 0-18.3 10.17-15.38 20.39L214.86 192H112l-43.2-57.6c-3.02-4.03-7.77-6.4-12.8-6.4H16.01C5.6 128-2.04 137.78.49 147.88L32 256 .49 364.12C-2.04 374.22 5.6 384 16.01 384H56c5.04 0 9.78-2.37 12.8-6.4L112 320h102.86l-49.03 171.6c-2.92 10.22 4.75 20.4 15.38 20.4h65.5c5.74 0 11.04-3.08 13.89-8.06L365.71 320H480c35.35 0 96-28.65 96-64s-60.65-64-96-64z',
		}
	},
	{
		name: 'Accountants',
		description: 'description',
		type: 'accounting',
		icon: 'fa-money-check-edit-alt',
		marker: {
			path: 'M204.78 116.47l4.1 4.1 71.69-71.8L238.78 7a24.1 24.1 0 0 0-33.9 0L167 44.87a24 24 0 0 0 0 33.8zM608 160H437l120.25 120.28A64 64 0 0 1 576 325.54V400a16 16 0 0 1-16 16H264a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h163l-64-64h-99a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h67L203 160H32a32 32 0 0 0-32 32v288a32 32 0 0 0 32 32h576a32 32 0 0 0 32-32V192a32 32 0 0 0-32-32zM144 415.88V432a8 8 0 0 1-8 8h-16a8 8 0 0 1-8-8v-16.29a57.26 57.26 0 0 1-31.37-11.35 8 8 0 0 1-.57-12.14L91.81 381a8.21 8.21 0 0 1 10.13-.73 24.08 24.08 0 0 0 12.82 3.73h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V240a8 8 0 0 1 8-8h16a8 8 0 0 1 8 8v16.29a57.18 57.18 0 0 1 31.37 11.35 8 8 0 0 1 .57 12.14L164.18 291a8.22 8.22 0 0 1-10.14.73 23.93 23.93 0 0 0-12.81-3.73h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.57 23.42 31.57 43.39 0 24.53-19.04 44.44-42.66 45.07zm318.87-41.25a32 32 0 0 0 22.62 9.37H528a16 16 0 0 0 16-16v-42.46a32 32 0 0 0-9.37-22.63L303.17 71.47l-71.7 71.7z',
		}
	},
	{
		name: 'Amusement Parks',
		description: 'description',
		type: 'amusement_park',
		icon: 'fa-ticket-alt',
		marker: {
			path: 'M424 160H152c-13.255 0-24 10.745-24 24v144c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V184c0-13.255-10.745-24-24-24zm-8 160H160V192h256v128zm128-96h32V112c0-26.51-21.49-48-48-48H48C21.49 64 0 85.49 0 112v112h32c17.673 0 32 14.327 32 32s-14.327 32-32 32H0v112c0 26.51 21.49 48 48 48h480c26.51 0 48-21.49 48-48V288h-32c-17.673 0-32-14.327-32-32s14.327-32 32-32zm0 96v80c0 8.823-7.177 16-16 16H48c-8.823 0-16-7.177-16-16v-80c35.29 0 64-28.71 64-64s-28.71-64-64-64v-80c0-8.823 7.177-16 16-16h480c8.823 0 16 7.177 16 16v80c-35.29 0-64 28.71-64 64s28.71 64 64 64z',
		}
	},
	{
		name: 'Aquariums',
		description: 'description',
		type: 'aquarium',
		icon: 'fa-fish',
		marker: {
			path: 'M327.1 96c-89.97 0-168.54 54.77-212.27 101.63L27.5 131.58c-12.13-9.18-30.24.6-27.14 14.66L24.54 256 .35 365.77c-3.1 14.06 15.01 23.83 27.14 14.66l87.33-66.05C158.55 361.23 237.13 416 327.1 416 464.56 416 576 288 576 256S464.56 96 327.1 96zm87.43 184c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24 13.26 0 24 10.74 24 24 0 13.25-10.75 24-24 24z',
		}
	},
	{
		name: 'Art Galleries',
		description: 'description',
		type: 'art_gallery',
		icon: 'fa-palette',
		marker: {
			path: 'M204.3 5C104.9 24.4 24.8 104.3 5.2 203.4c-37 187 131.7 326.4 258.8 306.7 41.2-6.4 61.4-54.6 42.5-91.7-23.1-45.4 9.9-98.4 60.9-98.4h79.7c35.8 0 64.8-29.6 64.9-65.3C511.5 97.1 368.1-26.9 204.3 5zM96 320c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm32-128c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128-64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z',
		}
	},
	{
		name: 'ATMs',
		description: 'description',
		type: 'atm',
		icon: 'fa-credit-card',
		marker: {
			path: 'M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM48 64h480c8.8 0 16 7.2 16 16v48H32V80c0-8.8 7.2-16 16-16zm480 384H48c-8.8 0-16-7.2-16-16V224h512v208c0 8.8-7.2 16-16 16zm-336-84v8c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v8c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z',
		}
	},
	{
		name: 'Bakeries',
		description: 'description',
		type: 'bakery',
		icon: 'fa-bread-slice',
		marker: {
			path: 'M288 0C108 0 0 93.4 0 169.14 0 199.44 24.24 224 64 224v256c0 17.67 16.12 32 36 32h376c19.88 0 36-14.33 36-32V224c39.76 0 64-24.56 64-54.86C576 93.4 468 0 288 0z',
		}
	},
	{
		name: 'Banks',
		description: 'description',
		type: 'bank',
		icon: 'fa-sack-dollar',
		marker: {
			path: 'M192 96h128l47.4-71.12A16 16 0 0 0 354.09 0H157.94a16 16 0 0 0-13.31 24.88zm128 32H192C-10.38 243.4.09 396.64.09 416c0 53 49.11 96 109.68 96h292.48c60.58 0 109.68-43 109.68-96 0-19 9.35-173.24-191.93-288zm-46.58 278v17.34a8.69 8.69 0 0 1-8.7 8.62h-17.41a8.69 8.69 0 0 1-8.71-8.62v-17.51a63.19 63.19 0 0 1-34.16-12.17 8.55 8.55 0 0 1-.66-13l12.84-12.06a8.92 8.92 0 0 1 11-.76 26.72 26.72 0 0 0 13.93 4h30.58c7.07 0 12.84-6.35 12.84-14.22 0-6.46-3.92-12.06-9.58-13.67l-49-14.54c-20.24-6-34.39-25.2-34.39-46.74 0-26.38 20.68-47.82 46.46-48.57v-17.48a8.69 8.69 0 0 1 8.74-8.62h17.41a8.68 8.68 0 0 1 8.7 8.62v17.55a63.12 63.12 0 0 1 34.17 12.17 8.55 8.55 0 0 1 .65 13l-12.73 12.2a8.92 8.92 0 0 1-11 .75 26.78 26.78 0 0 0-13.93-4h-30.56c-7.07 0-12.84 6.35-12.84 14.21 0 6.46 3.92 12.06 9.57 13.68l49 14.54c20.24 6 34.38 25.2 34.38 46.74-.14 26.4-20.92 47.94-46.6 48.54z',
		}
	},
	{
		name: 'Bars',
		description: 'description',
		type: 'bar',
		icon: 'fa-beer',
		marker: {
			path: 'M368 96h-48V56c0-13.255-10.745-24-24-24H24C10.745 32 0 42.745 0 56v400c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24v-42.11l80.606-35.977C429.396 365.063 448 336.388 448 304.86V176c0-44.112-35.888-80-80-80zm16 208.86a16.018 16.018 0 0 1-9.479 14.611L320 343.805V160h48c8.822 0 16 7.178 16 16v128.86zM208 384c-8.836 0-16-7.164-16-16V144c0-8.836 7.164-16 16-16s16 7.164 16 16v224c0 8.836-7.164 16-16 16zm-96 0c-8.836 0-16-7.164-16-16V144c0-8.836 7.164-16 16-16s16 7.164 16 16v224c0 8.836-7.164 16-16 16z',
		}
	},
	{
		name: 'Beauty Salons',
		description: 'description',
		type: 'beauty_salon',
		icon: 'fa-air-freshener',
		marker: {
			path: 'M224 160H96C43 160 0 203 0 256V480C0 497.625 14.375 512 32 512H288C305.625 512 320 497.625 320 480V256C320 203 277 160 224 160ZM160 416C115.875 416 80 380.125 80 336S115.875 256 160 256S240 291.875 240 336S204.125 416 160 416ZM224 32C224 14.375 209.625 0 192 0H128C110.375 0 96 14.375 96 32V128H224V32ZM381.781 51.578C383 50.969 384 49.359 384 48C384 46.625 383 45.031 381.781 44.422L352 32L339.562 2.219C338.969 1 337.375 0 336 0S333.031 1 332.406 2.219L320 32L290.219 44.422C289 45.031 288 46.625 288 48C288 49.359 289 50.969 290.219 51.578L320 64L332.406 93.781C333.031 95 334.625 96 336 96S338.969 95 339.562 93.781L352 64L381.781 51.578ZM448 64L460.406 93.781C461.031 95 462.625 96 464 96S466.969 95 467.562 93.781L480 64L509.781 51.578C511 50.969 512 49.359 512 48C512 46.625 511 45.031 509.781 44.422L480 32L467.562 2.219C466.969 1 465.375 0 464 0S461.031 1 460.406 2.219L448 32L418.219 44.422C417 45.031 416 46.625 416 48C416 49.359 417 50.969 418.219 51.578L448 64ZM480 224L467.562 194.219C466.969 193 465.375 192 464 192S461.031 193 460.406 194.219L448 224L418.219 236.422C417 237.031 416 238.625 416 240C416 241.359 417 242.969 418.219 243.578L448 256L460.406 285.781C461.031 287 462.625 288 464 288S466.969 287 467.562 285.781L480 256L509.781 243.578C511 242.969 512 241.359 512 240C512 238.625 511 237.031 509.781 236.422L480 224ZM445.781 147.578C447 146.969 448 145.359 448 144C448 142.625 447 141.031 445.781 140.422L416 128L403.562 98.219C402.969 97 401.375 96 400 96S397.031 97 396.406 98.219L384 128L354.219 140.422C353 141.031 352 142.625 352 144C352 145.359 353 146.969 354.219 147.578L384 160L396.406 189.781C397.031 191 398.625 192 400 192S402.969 191 403.562 189.781L416 160L445.781 147.578Z',
		}
	},
	{
		name: 'Bicycle Stores',
		description: 'description',
		type: 'bicycle_store',
		icon: 'fa-biking',
		marker: {
			path: 'M400 96a48 48 0 1 0-48-48 48 48 0 0 0 48 48zm-4 121a31.9 31.9 0 0 0 20 7h64a32 32 0 0 0 0-64h-52.78L356 103a31.94 31.94 0 0 0-40.81.68l-112 96a32 32 0 0 0 3.08 50.92L288 305.12V416a32 32 0 0 0 64 0V288a32 32 0 0 0-14.25-26.62l-41.36-27.57 58.25-49.92zm116 39a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 192a64 64 0 1 1 64-64 64 64 0 0 1-64 64zM128 256a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 192a64 64 0 1 1 64-64 64 64 0 0 1-64 64z',
		}
	},
	{
		name: 'Bowling Alleys',
		description: 'description',
		type: 'bowling_alley',
		icon: 'fa-bowling-ball',
		marker: {
			path: 'M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zM120 192c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm64-96c0-17.7 14.3-32 32-32s32 14.3 32 32-14.3 32-32 32-32-14.3-32-32zm48 144c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z',
		}
	},
	{
		name: 'Book Stores',
		description: 'description',
		type: 'book_store',
		icon: 'fa-book-alt',
		marker: {
			path: 'M448 358.4V25.6c0-16-9.6-25.6-25.6-25.6H96C41.6 0 0 41.6 0 96v320c0 54.4 41.6 96 96 96h326.4c12.8 0 25.6-9.6 25.6-25.6v-16c0-6.4-3.2-12.8-9.6-19.2-3.2-16-3.2-60.8 0-73.6 6.4-3.2 9.6-9.6 9.6-19.2zM380.8 448H96c-19.2 0-32-12.8-32-32s16-32 32-32h284.8v64z',
		}
	},
	{
		name: 'Bus Stations',
		description: 'description',
		type: 'bus_station',
		icon: 'fa-bus',
		marker: {
			path: 'M488 128h-8V80c0-44.8-99.2-80-224-80S32 35.2 32 80v48h-8c-13.25 0-24 10.74-24 24v80c0 13.25 10.75 24 24 24h8v160c0 17.67 14.33 32 32 32v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h192v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h6.4c16 0 25.6-12.8 25.6-25.6V256h8c13.25 0 24-10.75 24-24v-80c0-13.26-10.75-24-24-24zM112 400c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm16-112c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h256c17.67 0 32 14.33 32 32v128c0 17.67-14.33 32-32 32H128zm272 112c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z',
		}
	},
	{
		name: 'Cafes',
		description: 'description',
		type: 'cafe',
		icon: 'fa-mug-hot',
		marker: {
			path: 'M127.1 146.5c1.3 7.7 8 13.5 16 13.5h16.5c9.8 0 17.6-8.5 16.3-18-3.8-28.2-16.4-54.2-36.6-74.7-14.4-14.7-23.6-33.3-26.4-53.5C111.8 5.9 105 0 96.8 0H80.4C70.6 0 63 8.5 64.1 18c3.9 31.9 18 61.3 40.6 84.4 12 12.2 19.7 27.5 22.4 44.1zm112 0c1.3 7.7 8 13.5 16 13.5h16.5c9.8 0 17.6-8.5 16.3-18-3.8-28.2-16.4-54.2-36.6-74.7-14.4-14.7-23.6-33.3-26.4-53.5C223.8 5.9 217 0 208.8 0h-16.4c-9.8 0-17.5 8.5-16.3 18 3.9 31.9 18 61.3 40.6 84.4 12 12.2 19.7 27.5 22.4 44.1zM400 192H32c-17.7 0-32 14.3-32 32v192c0 53 43 96 96 96h192c53 0 96-43 96-96h16c61.8 0 112-50.2 112-112s-50.2-112-112-112zm0 160h-16v-96h16c26.5 0 48 21.5 48 48s-21.5 48-48 48z',
		}
	},
	{
		name: 'Campgrounds',
		description: 'description',
		type: 'campground',
		icon: 'fa-campground',
		marker: {
			path: 'M624 448h-24.68L359.54 117.75l53.41-73.55c5.19-7.15 3.61-17.16-3.54-22.35l-25.9-18.79c-7.15-5.19-17.15-3.61-22.35 3.55L320 63.3 278.83 6.6c-5.19-7.15-15.2-8.74-22.35-3.55l-25.88 18.8c-7.15 5.19-8.74 15.2-3.54 22.35l53.41 73.55L40.68 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM320 288l116.36 160H203.64L320 288z',
		}
	},
	{
		name: 'Car Dealers',
		description: 'description',
		type: 'car_dealer',
		icon: 'fa-cars',
		marker: {
			path: 'M252.13 243.23A131.77 131.77 0 0 1 375.07 160h38.41a57.49 57.49 0 0 0-32.9-36.44L376 112.2l-17.84-49.08A99.92 99.92 0 0 0 264.93 0H151.07a99.92 99.92 0 0 0-93.23 63.12L40 112.2l-4.55 11.36A57.39 57.39 0 0 0 0 176.55v28.69a57 57 0 0 0 21.52 44.46V288a32 32 0 0 0 32 32h22.07a32 32 0 0 0 32-32v-32h139.88zM111.11 92c6.53-16.92 22.36-28 40-28h113.82c17.6 0 33.42 11.1 40 28L317 128H99zM76 215.85c-14.4 0-24-9.57-24-23.92S61.6 168 76 168s36 21.53 36 35.89-21.6 11.96-36 11.96zm528.58 99.71L600 304.2l-17.87-49.08a99.92 99.92 0 0 0-93.2-63.12H375.07a99.92 99.92 0 0 0-93.23 63.12L264 304.2l-4.55 11.36a57.39 57.39 0 0 0-35.42 53v28.69a57 57 0 0 0 21.52 44.46V480a32 32 0 0 0 32 32h22.07a32 32 0 0 0 32-32v-32h200.79v32a32 32 0 0 0 32 32h22.07a32 32 0 0 0 32-32v-38.3A57 57 0 0 0 640 397.24v-28.69a57.39 57.39 0 0 0-35.42-52.99zM335.11 284c6.53-16.92 22.36-28 40-28h113.82c17.6 0 33.42 11.1 40 28L541 320H323zM300 407.85c-14.4 0-24-9.57-24-23.93S285.6 360 300 360s36 21.53 36 35.89-21.6 11.96-36 11.96zm264 0c-14.4 0-36 2.39-36-12S549.6 360 564 360s24 9.57 24 23.92-9.6 23.93-24 23.93z',
		}
	},
	{
		name: 'Car Parks',
		description: 'description',
		type: 'parking',
		icon: 'fa-parking',
		marker: {
			path: 'M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM240 320h-48v48c0 8.8-7.2 16-16 16h-32c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16h96c52.9 0 96 43.1 96 96s-43.1 96-96 96zm0-128h-48v64h48c17.6 0 32-14.4 32-32s-14.4-32-32-32z',
		}
	},
	{
		name: 'Car Repair',
		description: 'description',
		type: 'car_repair',
		icon: 'fa-car-mechanic',
		marker: {
			path: 'M503.91 104h-55.98l-24-24 24-24h55.97c5.95 0 9.9-6.31 7.25-11.64-15.19-30.52-49.01-50.04-86.84-42.87-25.65 4.86-46.72 22.98-57.05 46.51H145.01c-12.38-28.17-40.2-48-72.94-48C40.75 0 13.9 18.12.84 44.37-1.81 49.69 2.15 56 8.09 56h55.98l24 24-24 24H8.09c-5.95 0-9.9 6.31-7.25 11.64 15.19 30.52 49.01 50.04 86.84 42.87 25.65-4.86 46.73-22.99 57.05-46.51h222.25c12.38 28.17 40.2 48 72.94 48 31.32 0 58.17-18.12 71.23-44.37 2.66-5.33-1.3-11.63-7.24-11.63zm-55.42 188.91l-5.07-12.67-19.93-49.83c-17.11-42.77-57.92-70.4-103.99-70.4h-127c-46.06 0-86.88 27.63-103.99 70.4l-19.93 49.82-5.07 12.67C40.33 302.52 24 325.34 24 352v32c0 20.12 9.48 37.86 24 49.59V480c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h224v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-46.41c14.52-11.73 24-29.47 24-49.59v-32c0-26.66-16.33-49.48-39.51-59.09zM96 399.8c-19.2 0-32-12.76-32-31.9S76.8 336 96 336s48 28.71 48 47.85-28.8 15.95-48 15.95zm38.4-111.79l13.53-33.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17l13.53 33.83H134.4zM416 399.8c-19.2 0-48 3.19-48-15.95S396.8 336 416 336s32 12.76 32 31.9-12.8 31.9-32 31.9z',
		}
	},
	{
		name: 'Casinos',
		description: 'description',
		type: 'casino',
		icon: 'fa-coins',
		marker: {
			path: 'M0 405.3V448c0 35.3 86 64 192 64s192-28.7 192-64v-42.7C342.7 434.4 267.2 448 192 448S41.3 434.4 0 405.3zM320 128c106 0 192-28.7 192-64S426 0 320 0 128 28.7 128 64s86 64 192 64zM0 300.4V352c0 35.3 86 64 192 64s192-28.7 192-64v-51.6c-41.3 34-116.9 51.6-192 51.6S41.3 334.4 0 300.4zm416 11c57.3-11.1 96-31.7 96-55.4v-42.7c-23.2 16.4-57.3 27.6-96 34.5v63.6zM192 160C86 160 0 195.8 0 240s86 80 192 80 192-35.8 192-80-86-80-192-80zm219.3 56.3c60-10.8 100.7-32 100.7-56.3v-42.7c-35.5 25.1-96.5 38.6-160.7 41.8 29.5 14.3 51.2 33.5 60 57.2z',
		}
	},
	{
		name: 'Car Washes',
		description: 'description',
		type: 'car_wash',
		icon: 'fa-car-wash',
		marker: {
			path: 'M349.33 85.33C349.33 61.77 392 0 392 0s42.67 61.77 42.67 85.33S415.56 128 392 128s-42.67-19.11-42.67-42.67zM232 128c23.56 0 42.67-19.1 42.67-42.67S232 0 232 0s-42.67 61.77-42.67 85.33S208.44 128 232 128zm-160 0c23.56 0 42.67-19.1 42.67-42.67S72 0 72 0 29.33 61.77 29.33 85.33 48.44 128 72 128zm392 224v32c0 20.12-9.48 37.86-24 49.59V480c0 17.67-14.33 32-32 32h-32c-17.67 0-32-14.33-32-32v-32H120v32c0 17.67-14.33 32-32 32H56c-17.67 0-32-14.33-32-32v-46.41C9.48 421.86 0 404.13 0 384v-32c0-26.67 16.33-49.48 39.51-59.1l5.07-12.67 19.93-49.82c17.11-42.77 57.92-70.4 103.99-70.4h127c46.06 0 86.88 27.63 103.99 70.4l19.93 49.83 5.07 12.67C447.67 302.52 464 325.34 464 352zm-344 31.85C120 364.71 91.2 336 72 336s-32 12.76-32 31.9 12.8 31.9 32 31.9 48 3.19 48-15.95zm233.6-95.84l-13.53-33.83c-7.29-18.22-24.94-30.17-44.57-30.17h-127c-19.63 0-37.28 11.95-44.57 30.17l-13.53 33.83h243.2zM424 367.9c0-19.14-12.8-31.9-32-31.9s-48 28.71-48 47.85 28.8 15.95 48 15.95 32-12.76 32-31.9z',
		}
	},
	{
		name: 'Cemeteries',
		description: 'description',
		type: 'cemetery',
		icon: 'fa-tombstone-alt',
		marker: {
			path: 'M496 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h480c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zm-48-256C448 85.96 362.04 0 256 0S64 85.96 64 192v224h384V192z',
		}
	},
	{
		name: 'Churches',
		description: 'description',
		type: 'church',
		icon: 'fa-church',
		marker: {
			path: 'M464.46 246.68L352 179.2V128h48c8.84 0 16-7.16 16-16V80c0-8.84-7.16-16-16-16h-48V16c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v48h-48c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h48v51.2l-112.46 67.48A31.997 31.997 0 0 0 160 274.12V512h96v-96c0-35.35 28.65-64 64-64s64 28.65 64 64v96h96V274.12c0-11.24-5.9-21.66-15.54-27.44zM0 395.96V496c0 8.84 7.16 16 16 16h112V320L19.39 366.54A32.024 32.024 0 0 0 0 395.96zm620.61-29.42L512 320v192h112c8.84 0 16-7.16 16-16V395.96c0-12.8-7.63-24.37-19.39-29.42z',
		}
	},
	{
		name: 'Clothing Stores',
		description: 'description',
		type: 'clothing_store',
		icon: 'fa-tshirt',
		marker: {
			path: 'M631.2 96.5L436.5 0C416.4 27.8 371.9 47.2 320 47.2S223.6 27.8 203.5 0L8.8 96.5c-7.9 4-11.1 13.6-7.2 21.5l57.2 114.5c4 7.9 13.6 11.1 21.5 7.2l56.6-27.7c10.6-5.2 23 2.5 23 14.4V480c0 17.7 14.3 32 32 32h256c17.7 0 32-14.3 32-32V226.3c0-11.8 12.4-19.6 23-14.4l56.6 27.7c7.9 4 17.5.8 21.5-7.2L638.3 118c4-7.9.8-17.6-7.1-21.5z',
		}
	},
	{
		name: 'Convenience Stores',
		description: 'description',
		type: 'convenience_store',
		icon: 'fa-store',
		marker: {
			path: 'M602 118.6L537.1 15C531.3 5.7 521 0 510 0H106C95 0 84.7 5.7 78.9 15L14 118.6c-33.5 53.5-3.8 127.9 58.8 136.4 4.5.6 9.1.9 13.7.9 29.6 0 55.8-13 73.8-33.1 18 20.1 44.3 33.1 73.8 33.1 29.6 0 55.8-13 73.8-33.1 18 20.1 44.3 33.1 73.8 33.1 29.6 0 55.8-13 73.8-33.1 18.1 20.1 44.3 33.1 73.8 33.1 4.7 0 9.2-.3 13.7-.9 62.8-8.4 92.6-82.8 59-136.4zM529.5 288c-10 0-19.9-1.5-29.5-3.8V384H116v-99.8c-9.6 2.2-19.5 3.8-29.5 3.8-6 0-12.1-.4-18-1.2-5.6-.8-11.1-2.1-16.4-3.6V480c0 17.7 14.3 32 32 32h448c17.7 0 32-14.3 32-32V283.2c-5.4 1.6-10.8 2.9-16.4 3.6-6.1.8-12.1 1.2-18.2 1.2z',
		}
	},
	{
		name: 'Courthouses',
		description: 'description',
		type: 'courthouse',
		icon: 'fa-gavel',
		marker: {
			path: 'M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z',
		}
	},
	{
		name: 'Dentists',
		description: 'description',
		type: 'dentist',
		icon: 'fa-tooth',
		marker: {
			path: 'M443.98 96.25c-11.01-45.22-47.11-82.06-92.01-93.72-32.19-8.36-63 5.1-89.14 24.33-3.25 2.39-6.96 3.73-10.5 5.48l28.32 18.21c7.42 4.77 9.58 14.67 4.8 22.11-4.46 6.95-14.27 9.86-22.11 4.8L162.83 12.84c-20.7-10.85-43.38-16.4-66.81-10.31-44.9 11.67-81 48.5-92.01 93.72-10.13 41.62-.42 80.81 21.5 110.43 23.36 31.57 32.68 68.66 36.29 107.35 4.4 47.16 10.33 94.16 20.94 140.32l7.8 33.95c3.19 13.87 15.49 23.7 29.67 23.7 13.97 0 26.15-9.55 29.54-23.16l34.47-138.42c4.56-18.32 20.96-31.16 39.76-31.16s35.2 12.85 39.76 31.16l34.47 138.42c3.39 13.61 15.57 23.16 29.54 23.16 14.18 0 26.48-9.83 29.67-23.7l7.8-33.95c10.61-46.15 16.53-93.16 20.94-140.32 3.61-38.7 12.93-75.78 36.29-107.35 21.95-29.61 31.66-68.8 21.53-110.43z',
		}
	},
	{
		name: 'Department Stores',
		description: 'description',
		type: 'department_store',
		icon: 'fa-building',
		marker: {
			path: 'M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z',
		}
	},
	{
		name: 'Doctors',
		description: 'description',
		type: 'doctor',
		icon: 'fa-stethoscope',
		marker: {
			path: 'M447.1 112c-34.2.5-62.3 28.4-63 62.6-.5 24.3 12.5 45.6 32 56.8V344c0 57.3-50.2 104-112 104-60 0-109.2-44.1-111.9-99.2C265 333.8 320 269.2 320 192V36.6c0-11.4-8.1-21.3-19.3-23.5L237.8.5c-13-2.6-25.6 5.8-28.2 18.8L206.4 35c-2.6 13 5.8 25.6 18.8 28.2l30.7 6.1v121.4c0 52.9-42.2 96.7-95.1 97.2-53.4.5-96.9-42.7-96.9-96V69.4l30.7-6.1c13-2.6 21.4-15.2 18.8-28.2l-3.1-15.7C107.7 6.4 95.1-2 82.1.6L19.3 13C8.1 15.3 0 25.1 0 36.6V192c0 77.3 55.1 142 128.1 156.8C130.7 439.2 208.6 512 304 512c97 0 176-75.4 176-168V231.4c19.1-11.1 32-31.7 32-55.4 0-35.7-29.2-64.5-64.9-64zm.9 80c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16z',
		}
	},
	{
		name: 'Electricians',
		description: 'description',
		type: 'electrician',
		icon: 'fa-bolt',
		marker: {
			path: 'M296 160H180.6l42.6-129.8C227.2 15 215.7 0 200 0H56C44 0 33.8 8.9 32.2 20.8l-32 240C-1.7 275.2 9.5 288 24 288h118.7L96.6 482.5c-3.6 15.2 8 29.5 23.3 29.5 8.4 0 16.4-4.4 20.8-12l176-304c9.3-15.9-2.2-36-20.7-36z',
		}
	},
	{
		name: 'Electronic Stores',
		description: 'description',
		type: 'electronics_store',
		icon: 'fa-tv-retro',
		marker: {
			path: 'M464 96H338.8l35.7-41.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 94.2 182.8 9.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L173.2 96H48c-26.5 0-48 21.5-48 48v288c0 26.5 21.5 48 48 48h16v32h48l21.3-32h245.3l21.3 32h48v-32h16c26.5 0 48-21.5 48-48V144c.1-26.5-21.4-48-47.9-48zm-72 312s0 8-168 8c-152 0-152-8-152-8s-8 0-8-120 8-120 8-120 0-8 152-8c168 0 168 8 168 8s8 0 8 120-8 120-8 120zm72-100c0 6.6-5.4 12-12 12h-8c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h8c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12h-8c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h8c6.6 0 12 5.4 12 12v8z',
		}
	},
	{
		name: 'Fire Stations',
		description: 'description',
		type: 'fire_station',
		icon: 'fa-siren-on',
		marker: {
			path: 'M224.21,134.94a8,8,0,0,1,9-6.87l15.86,2.13a8,8,0,0,1,6.87,9L231.82,320H496L471,120.06A64,64,0,0,0,407.5,64h-175A64,64,0,0,0,169,120.06L144,320h55.54ZM528,352H112a16,16,0,0,0-16,16v64a16,16,0,0,0,16,16H528a16,16,0,0,0,16-16V368A16,16,0,0,0,528,352ZM112,192a24,24,0,0,0-24-24H24a24,24,0,0,0,0,48H88A24,24,0,0,0,112,192Zm504-24H552a24,24,0,0,0,0,48h64a24,24,0,0,0,0-48ZM90.69,76a24,24,0,1,0,26.62-39.92l-48-32A24,24,0,1,0,42.69,44ZM536,80a23.87,23.87,0,0,0,13.29-4l48-32A24,24,0,1,0,570.69,4.06l-48,32A24,24,0,0,0,536,80Z',
		}
	},
	{
		name: 'Florists',
		description: 'description',
		type: 'florist',
		icon: 'fa-flower',
		marker: {
			path: 'M480 160A128 128 0 0 0 352 32c-38.45 0-72.54 17.3-96 44.14C232.54 49.3 198.45 32 160 32A128 128 0 0 0 32 160c0 38.45 17.3 72.54 44.14 96C49.3 279.46 32 313.55 32 352a128 128 0 0 0 128 128c38.45 0 72.54-17.3 96-44.14C279.46 462.7 313.55 480 352 480a128 128 0 0 0 128-128c0-38.45-17.3-72.54-44.14-96C462.7 232.54 480 198.45 480 160zM256 336a80 80 0 1 1 80-80 80 80 0 0 1-80 80z',
		}
	},
	{
		name: 'Gas Stations',
		description: 'description',
		type: 'gas_station',
		icon: 'fa-gas-pump',
		marker: {
			path: 'M336 448H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h320c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm157.2-340.7l-81-81c-6.2-6.2-16.4-6.2-22.6 0l-11.3 11.3c-6.2 6.2-6.2 16.4 0 22.6L416 97.9V160c0 28.1 20.9 51.3 48 55.2V376c0 13.2-10.8 24-24 24s-24-10.8-24-24v-32c0-48.6-39.4-88-88-88h-8V64c0-35.3-28.7-64-64-64H96C60.7 0 32 28.7 32 64v352h288V304h8c22.1 0 40 17.9 40 40v27.8c0 37.7 27 72 64.5 75.9 43 4.3 79.5-29.5 79.5-71.7V152.6c0-17-6.8-33.3-18.8-45.3zM256 192H96V64h160v128z',
		}
	},
	{
		name: 'Groceries',
		description: 'description',
		type: 'grocery_or_supermarket',
		icon: 'fa-shopping-basket',
		marker: {
			path: 'M576 216v16c0 13.255-10.745 24-24 24h-8l-26.113 182.788C514.509 462.435 494.257 480 470.37 480H105.63c-23.887 0-44.139-17.565-47.518-41.212L32 256h-8c-13.255 0-24-10.745-24-24v-16c0-13.255 10.745-24 24-24h67.341l106.78-146.821c10.395-14.292 30.407-17.453 44.701-7.058 14.293 10.395 17.453 30.408 7.058 44.701L170.477 192h235.046L326.12 82.821c-10.395-14.292-7.234-34.306 7.059-44.701 14.291-10.395 34.306-7.235 44.701 7.058L484.659 192H552c13.255 0 24 10.745 24 24zM312 392V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm112 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm-224 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24z',
		}
	},
	{
		name: 'Gyms',
		description: 'description',
		type: 'gym',
		icon: 'fa-dumbbell',
		marker: {
			path: 'M104 96H56c-13.3 0-24 10.7-24 24v104H8c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h24v104c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V120c0-13.3-10.7-24-24-24zm528 128h-24V120c0-13.3-10.7-24-24-24h-48c-13.3 0-24 10.7-24 24v272c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V288h24c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM456 32h-48c-13.3 0-24 10.7-24 24v168H256V56c0-13.3-10.7-24-24-24h-48c-13.3 0-24 10.7-24 24v400c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V288h128v168c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24z',
		}
	},
	{
		name: 'Hairdressers',
		description: 'description',
		type: 'hair_care',
		icon: 'fa-cut',
		marker: {
			path: 'M278.06 256L444.48 89.57c4.69-4.69 4.69-12.29 0-16.97-32.8-32.8-85.99-32.8-118.79 0L210.18 188.12l-24.86-24.86c4.31-10.92 6.68-22.81 6.68-35.26 0-53.02-42.98-96-96-96S0 74.98 0 128s42.98 96 96 96c4.54 0 8.99-.32 13.36-.93L142.29 256l-32.93 32.93c-4.37-.61-8.83-.93-13.36-.93-53.02 0-96 42.98-96 96s42.98 96 96 96 96-42.98 96-96c0-12.45-2.37-24.34-6.68-35.26l24.86-24.86L325.69 439.4c32.8 32.8 85.99 32.8 118.79 0 4.69-4.68 4.69-12.28 0-16.97L278.06 256zM96 160c-17.64 0-32-14.36-32-32s14.36-32 32-32 32 14.36 32 32-14.36 32-32 32zm0 256c-17.64 0-32-14.36-32-32s14.36-32 32-32 32 14.36 32 32-14.36 32-32 32z',
		}
	},
	{
		name: 'Hardware Stores',
		description: 'description',
		type: 'hardware_store',
		icon: 'fa-hammer',
		marker: {
			path: 'M571.31 193.94l-22.63-22.63c-6.25-6.25-16.38-6.25-22.63 0l-11.31 11.31-28.9-28.9c5.63-21.31.36-44.9-16.35-61.61l-45.25-45.25c-62.48-62.48-163.79-62.48-226.28 0l90.51 45.25v18.75c0 16.97 6.74 33.25 18.75 45.25l49.14 49.14c16.71 16.71 40.3 21.98 61.61 16.35l28.9 28.9-11.31 11.31c-6.25 6.25-6.25 16.38 0 22.63l22.63 22.63c6.25 6.25 16.38 6.25 22.63 0l90.51-90.51c6.23-6.24 6.23-16.37-.02-22.62zm-286.72-15.2c-3.7-3.7-6.84-7.79-9.85-11.95L19.64 404.96c-25.57 23.88-26.26 64.19-1.53 88.93s65.05 24.05 88.93-1.53l238.13-255.07c-3.96-2.91-7.9-5.87-11.44-9.41l-49.14-49.14z',
		}
	},
	{
		name: 'Hindu Temples',
		description: 'description',
		type: 'hindu_temple',
		icon: 'fa-om',
		marker: {
			path: 'M360.6 60.94a10.43 10.43 0 0 0 14.76 0l21.57-21.56a10.43 10.43 0 0 0 0-14.76L375.35 3.06c-4.08-4.07-10.68-4.07-14.76 0l-21.57 21.56a10.43 10.43 0 0 0 0 14.76l21.58 21.56zM412.11 192c-26.69 0-51.77 10.39-70.64 29.25l-24.25 24.25c-6.78 6.77-15.78 10.5-25.38 10.5H245c10.54-22.1 14.17-48.11 7.73-75.23-10.1-42.55-46.36-76.11-89.52-83.19-36.15-5.93-70.9 5.04-96.01 28.78-7.36 6.96-6.97 18.85 1.12 24.93l26.15 19.63c5.72 4.3 13.66 4.32 19.2-.21 8.45-6.9 19.02-10.71 30.27-10.71 26.47 0 48.01 21.53 48.01 48s-21.54 48-48.01 48h-31.9c-11.96 0-19.74 12.58-14.39 23.28l16.09 32.17c2.53 5.06 7.6 8.1 13.17 8.55h33.03c35.3 0 64.01 28.7 64.01 64s-28.71 64-64.01 64c-96.02 0-122.35-54.02-145.15-92.03-4.53-7.55-14.77-3.58-14.79 5.22C-.09 416 41.13 512 159.94 512c70.59 0 128.02-57.42 128.02-128 0-23.42-6.78-45.1-17.81-64h21.69c26.69 0 51.77-10.39 70.64-29.25l24.25-24.25c6.78-6.77 15.78-10.5 25.38-10.5 19.78 0 35.88 16.09 35.88 35.88V392c0 13.23-18.77 24-32.01 24-39.4 0-66.67-24.24-81.82-42.89-4.77-5.87-14.2-2.54-14.2 5.02V416s0 64 96.02 64c48.54 0 96.02-39.47 96.02-88V291.88c0-55.08-44.8-99.88-99.89-99.88zm42.18-124.73c-85.55 65.12-169.05 2.75-172.58.05-6.02-4.62-14.44-4.38-20.14.55-5.74 4.92-7.27 13.17-3.66 19.8 1.61 2.95 40.37 72.34 118.8 72.34 79.92 0 98.78-31.36 101.75-37.66 1.02-2.12 1.53-4.47 1.53-6.83V80c0-13.22-15.14-20.69-25.7-12.73z',
		}
	},
	{
		name: 'Hospitals',
		description: 'description',
		type: 'hospital',
		icon: 'fa-h-square',
		marker: {
			path: 'M448 80v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48zm-112 48h-32c-8.837 0-16 7.163-16 16v80H160v-80c0-8.837-7.163-16-16-16h-32c-8.837 0-16 7.163-16 16v224c0 8.837 7.163 16 16 16h32c8.837 0 16-7.163 16-16v-80h128v80c0 8.837 7.163 16 16 16h32c8.837 0 16-7.163 16-16V144c0-8.837-7.163-16-16-16z',
		}
	},
	{
		name: 'Jewelry Stores',
		description: 'description',
		type: 'jewelry_store',
		icon: 'fa-gem',
		marker: {
			path: 'M485.5 0L576 160H474.9L405.7 0h79.8zm-128 0l69.2 160H149.3L218.5 0h139zm-267 0h79.8l-69.2 160H0L90.5 0zM0 192h100.7l123 251.7c1.5 3.1-2.7 5.9-5 3.3L0 192zm148.2 0h279.6l-137 318.2c-1 2.4-4.5 2.4-5.5 0L148.2 192zm204.1 251.7l123-251.7H576L357.3 446.9c-2.3 2.7-6.5-.1-5-3.2z',
		}
	},
	{
		name: 'Laundrettes',
		description: 'description',
		type: 'laundry',
		icon: 'fa-washer',
		marker: {
			path: 'M384 0H64A64 64 0 0 0 0 64v416a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a64 64 0 0 0-64-64zM184 64a24 24 0 1 1-24 24 24 24 0 0 1 24-24zM64 88a24 24 0 1 1 24 24 24 24 0 0 1-24-24zm160 360a144 144 0 1 1 144-144 144 144 0 0 1-144 144zm36.87-163.66a51.23 51.23 0 0 1-73.74 0 51.79 51.79 0 0 1-73.24 1A110.76 110.76 0 0 0 112 304a112 112 0 0 0 224 0 110.76 110.76 0 0 0-1.89-18.69 51.79 51.79 0 0 1-73.24-1z',
		}
	},
	{
		name: 'Lawyers',
		description: 'description',
		type: 'lawyer',
		icon: 'fa-balance-scale',
		marker: {
			path: 'M256 336h-.02c0-16.18 1.34-8.73-85.05-181.51-17.65-35.29-68.19-35.36-85.87 0C-2.06 328.75.02 320.33.02 336H0c0 44.18 57.31 80 128 80s128-35.82 128-80zM128 176l72 144H56l72-144zm511.98 160c0-16.18 1.34-8.73-85.05-181.51-17.65-35.29-68.19-35.36-85.87 0-87.12 174.26-85.04 165.84-85.04 181.51H384c0 44.18 57.31 80 128 80s128-35.82 128-80h-.02zM440 320l72-144 72 144H440zm88 128H352V153.25c23.51-10.29 41.16-31.48 46.39-57.25H528c8.84 0 16-7.16 16-16V48c0-8.84-7.16-16-16-16H383.64C369.04 12.68 346.09 0 320 0s-49.04 12.68-63.64 32H112c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h129.61c5.23 25.76 22.87 46.96 46.39 57.25V448H112c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h416c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z',
		}
	},
	{
		name: 'Liquor Stores',
		description: 'description',
		type: 'liquor_store',
		icon: 'fa-wine-bottle',
		marker: {
			path: 'M507.31 72.57L439.43 4.69c-6.25-6.25-16.38-6.25-22.63 0l-22.63 22.63c-6.25 6.25-6.25 16.38 0 22.63l-76.67 76.67c-46.58-19.7-102.4-10.73-140.37 27.23L18.75 312.23c-24.99 24.99-24.99 65.52 0 90.51l90.51 90.51c24.99 24.99 65.52 24.99 90.51 0l158.39-158.39c37.96-37.96 46.93-93.79 27.23-140.37l76.67-76.67c6.25 6.25 16.38 6.25 22.63 0l22.63-22.63c6.24-6.24 6.24-16.37-.01-22.62zM179.22 423.29l-90.51-90.51 122.04-122.04 90.51 90.51-122.04 122.04z',
		}
	},
	{
		name: 'Locksmiths',
		description: 'description',
		type: 'locksmith',
		icon: 'fa-key',
		marker: {
			path: 'M512 176.001C512 273.203 433.202 352 336 352c-11.22 0-22.19-1.062-32.827-3.069l-24.012 27.014A23.999 23.999 0 0 1 261.223 384H224v40c0 13.255-10.745 24-24 24h-40v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-78.059c0-6.365 2.529-12.47 7.029-16.971l161.802-161.802C163.108 213.814 160 195.271 160 176 160 78.798 238.797.001 335.999 0 433.488-.001 512 78.511 512 176.001zM336 128c0 26.51 21.49 48 48 48s48-21.49 48-48-21.49-48-48-48-48 21.49-48 48z',
		}
	},
	{
		name: 'Lodgings',
		description: 'description',
		type: 'lodging',
		icon: 'fa-hotel',
		marker: {
			path: 'M560 64c8.84 0 16-7.16 16-16V16c0-8.84-7.16-16-16-16H16C7.16 0 0 7.16 0 16v32c0 8.84 7.16 16 16 16h15.98v384H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h240v-80c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v80h240c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16h-16V64h16zm-304 44.8c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4zm0 96c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4zm-128-96c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4zM179.2 256h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4c0 6.4-6.4 12.8-12.8 12.8zM192 384c0-53.02 42.98-96 96-96s96 42.98 96 96H192zm256-140.8c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm0-96c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4z',
		}
	},
	{
		name: 'Mosques',
		description: 'description',
		type: 'mosque',
		icon: 'fa-mosque',
		marker: {
			path: 'M0 480c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V160H0v320zm579.16-192c17.86-17.39 28.84-37.34 28.84-58.91 0-52.86-41.79-93.79-87.92-122.9-41.94-26.47-80.63-57.77-111.96-96.22L400 0l-8.12 9.97c-31.33 38.45-70.01 69.76-111.96 96.22C233.79 135.3 192 176.23 192 229.09c0 21.57 10.98 41.52 28.84 58.91h358.32zM608 320H192c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h32v-64c0-17.67 14.33-32 32-32s32 14.33 32 32v64h64v-72c0-48 48-72 48-72s48 24 48 72v72h64v-64c0-17.67 14.33-32 32-32s32 14.33 32 32v64h32c17.67 0 32-14.33 32-32V352c0-17.67-14.33-32-32-32zM64 0S0 32 0 96v32h128V96c0-64-64-96-64-96z',
		}
	},
	{
		name: 'Movie Theaters',
		description: 'description',
		type: 'movie_theater',
		icon: 'fa-popcorn',
		marker: {
			path: 'M443.62 138.88a42.72 42.72 0 0 0-33.09-20.79 37.89 37.89 0 0 0-.33-37.43c-9.11-16-28-23.69-45.55-20.17-.33-16.64-11.47-32-29-37.43a43.36 43.36 0 0 0-39.14 6.4 4.25 4.25 0 0 0-.68-1.92C288.73 6.42 264.77-4.78 242.5 2a41.66 41.66 0 0 0-27.32 27.19 43.4 43.4 0 0 0-38.82-6.08c-17.54 5.44-28.66 20.79-29 37.43-17.56-3.54-36.46 4.12-45.57 20.12a37.18 37.18 0 0 0-.33 37.43c-13.46 1.28-26.32 8.64-33.06 20.79-3.92 6.74-4.77 14-4.27 21.12h383.73c.52-7.12-.33-14.37-4.24-21.12zM366.4 192l-28 256h-45.01L315 192H197.05l21.56 256h-45.05l-28-256H64l43.91 292.75A32 32 0 0 0 139.56 512h232.88a32 32 0 0 0 31.65-27.25L448 192z',
		}
	},
	{
		name: 'Moving Companies',
		description: 'description',
		type: 'moving_company',
		icon: 'fa-truck-couch',
		marker: {
			path: 'M24.1 400L320 320.7v-122c-8.3-4-18-5.6-27.6-3.1-21.3 5.7-34 27.7-28.3 49l6.2 23.2-185.4 49.7-6.2-23.2C73 273 51 260.3 29.7 266c-21.3 5.7-34 27.7-28.3 49l22.7 85zm-2.7-164.9c39.6-10.6 73.7 13.4 85.5 43.4L232 245c-5.4-35.7 16.5-70.7 52.2-80.2 2.6-.7 5.3-.7 8-1.1-9.9-36.9-45.9-55.1-78.7-46.3L58.9 158.8c-32.8 8.8-54.8 42.6-45.1 79.1 2.5-1 5-2.1 7.6-2.8zM384 0c-17.7 0-32 14.3-32 32v323.6L5.9 450c-4.3 1.2-6.8 5.6-5.6 9.8l12.6 46.3c1.2 4.3 5.6 6.8 9.8 5.6l393.7-107.4C418.8 464.1 467.6 512 528 512c61.9 0 112-50.1 112-112V0H384zm144 448c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z',
		}
	},
	{
		name: 'Night Clubs',
		description: 'description',
		type: 'night_club',
		icon: 'fa-glass-martini',
		marker: {
			path: 'M502.05 57.6C523.3 36.34 508.25 0 478.2 0H33.8C3.75 0-11.3 36.34 9.95 57.6L224 271.64V464h-56c-22.09 0-40 17.91-40 40 0 4.42 3.58 8 8 8h240c4.42 0 8-3.58 8-8 0-22.09-17.91-40-40-40h-56V271.64L502.05 57.6z',
		}
	},
	{
		name: 'Painters',
		description: 'description',
		type: 'painter',
		icon: 'fa-brush',
		marker: {
			path: 'M352 0H32C14.33 0 0 14.33 0 32v224h384V32c0-17.67-14.33-32-32-32zM0 320c0 35.35 28.66 64 64 64h64v64c0 35.35 28.66 64 64 64s64-28.65 64-64v-64h64c35.34 0 64-28.65 64-64v-32H0v32zm192 104c13.25 0 24 10.74 24 24 0 13.25-10.75 24-24 24s-24-10.75-24-24c0-13.26 10.75-24 24-24z',
		}
	},
	{
		name: 'Parks',
		description: 'description',
		type: 'park',
		icon: 'fa-tree-alt',
		marker: {
			path: 'M496 235c-10.67-16-25-27.67-43-35h1c8.67-16 11.83-32.5 9.5-49.5s-9.17-31.67-20.5-44S417.83 86 401.5 82s-32.83-2.33-49.5 5c-2.67-24.67-13.17-45.33-31.5-62S280.67 0 256 0s-46.17 8.33-64.5 25-28.83 37.33-31.5 62c-16.67-7.33-33.17-9-49.5-5S80.33 94.17 69 106.5s-18.17 27-20.5 44S49.67 184 59 200c-18 7.33-32.33 19-43 35S0 268.67 0 288c0 26.67 9.33 49.33 28 68s41.33 28 68 28h128v56.45l-30.29 48.4c-5.32 10.64 2.42 23.16 14.31 23.16h95.96c11.89 0 19.63-12.52 14.31-23.16L288 440.45V384h128c26.67 0 49.33-9.33 68-28s28-41.33 28-68c0-19.33-5.33-37-16-53z',
		}
	},
	{
		name: 'Pharmacies',
		description: 'description',
		type: 'pharmacy',
		icon: 'fa-pills',
		marker: {
			path: 'M112 32C50.1 32 0 82.1 0 144v224c0 61.9 50.1 112 112 112s112-50.1 112-112V144c0-61.9-50.1-112-112-112zm48 224H64V144c0-26.5 21.5-48 48-48s48 21.5 48 48v112zm139.7-29.7c-3.5-3.5-9.4-3.1-12.3.8-45.3 62.5-40.4 150.1 15.9 206.4 56.3 56.3 143.9 61.2 206.4 15.9 4-2.9 4.3-8.8.8-12.3L299.7 226.3zm229.8-19c-56.3-56.3-143.9-61.2-206.4-15.9-4 2.9-4.3 8.8-.8 12.3l210.8 210.8c3.5 3.5 9.4 3.1 12.3-.8 45.3-62.6 40.5-150.1-15.9-206.4z',
		}
	},
	{
		name: 'Post Offices',
		description: 'description',
		type: 'post_office',
		icon: 'fa-envelope',
		marker: {
			path: 'M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z',
		}
	},
	{
		name: 'Restaurants',
		description: 'description',
		type: 'restaurant',
		icon: 'fa-utensils',
		marker: {
			path: 'M207.9 15.2c.8 4.7 16.1 94.5 16.1 128.8 0 52.3-27.8 89.6-68.9 104.6L168 486.7c.7 13.7-10.2 25.3-24 25.3H80c-13.7 0-24.7-11.5-24-25.3l12.9-238.1C27.7 233.6 0 196.2 0 144 0 109.6 15.3 19.9 16.1 15.2 19.3-5.1 61.4-5.4 64 16.3v141.2c1.3 3.4 15.1 3.2 16 0 1.4-25.3 7.9-139.2 8-141.8 3.3-20.8 44.7-20.8 47.9 0 .2 2.7 6.6 116.5 8 141.8.9 3.2 14.8 3.4 16 0V16.3c2.6-21.6 44.8-21.4 48-1.1zm119.2 285.7l-15 185.1c-1.2 14 9.9 26 23.9 26h56c13.3 0 24-10.7 24-24V24c0-13.2-10.7-24-24-24-82.5 0-221.4 178.5-64.9 300.9z',
		}
	},
	{
		name: 'RV Parks',
		description: 'description',
		type: 'rv_park',
		icon: 'fa-rv',
		marker: {
			path: 'M621.3 269.3L563 211c-12-12-28.4-18.8-45.4-18.8L416 192v240h.2c.1 34.1 22.1 66.3 54.9 76.2 54.8 16.6 105-23.9 105-76.2v-16h32c17.6 0 32-14.4 32-32v-69.5c-.1-17-6.8-33.2-18.8-45.2zM496 464c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm-16-176v-48h37.5c4.3 0 8.3 1.7 11.3 4.7l43.3 43.3H480zm128-160.8c-.4-52.6-43.2-95.2-96-95.2H384V16c0-8.8-7.2-16-16-16H240c-8.8 0-16 7.2-16 16v16H64C28.7 32 0 60.7 0 96v197.5c0 17 6.7 33.3 18.7 45.3L96 416v11.4c0 41.8 30.1 80 71.8 84.1 47.9 4.8 88.2-32.7 88.2-79.6v-16h128V160h192c17.9 0 32.1-14.8 32-32.8zM176 464c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm80-256c0 8.8-7.2 16-16 16H112c-8.8 0-16-7.2-16-16v-64c0-8.8 7.2-16 16-16h128c8.8 0 16 7.2 16 16v64z',
		}
	},
	{
		name: 'Spas',
		description: 'description',
		type: 'spa',
		icon: 'fa-spa',
		marker: {
			path: 'M568.25 192c-29.04.13-135.01 6.16-213.84 83-33.12 29.63-53.36 63.3-66.41 94.86-13.05-31.56-33.29-65.23-66.41-94.86-78.83-76.84-184.8-82.87-213.84-83-4.41-.02-7.79 3.4-7.75 7.82.23 27.92 7.14 126.14 88.77 199.3C172.79 480.94 256 480 288 480s115.19.95 199.23-80.88c81.64-73.17 88.54-171.38 88.77-199.3.04-4.42-3.34-7.84-7.75-7.82zM287.98 302.6c12.82-18.85 27.6-35.78 44.09-50.52 19.09-18.61 39.58-33.3 60.26-45.18-16.44-70.5-51.72-133.05-96.73-172.22-4.11-3.58-11.02-3.58-15.14 0-44.99 39.14-80.27 101.63-96.74 172.07 20.37 11.7 40.5 26.14 59.22 44.39a282.768 282.768 0 0 1 45.04 51.46z',
		}
	},
	{
		name: 'Stadiums',
		description: 'description',
		type: 'stadium',
		icon: 'fa-trophy',
		marker: {
			path: 'M552 64H448V24c0-13.3-10.7-24-24-24H152c-13.3 0-24 10.7-24 24v40H24C10.7 64 0 74.7 0 88v56c0 35.7 22.5 72.4 61.9 100.7 31.5 22.7 69.8 37.1 110 41.7C203.3 338.5 240 360 240 360v72h-48c-35.3 0-64 20.7-64 56v12c0 6.6 5.4 12 12 12h296c6.6 0 12-5.4 12-12v-12c0-35.3-28.7-56-64-56h-48v-72s36.7-21.5 68.1-73.6c40.3-4.6 78.6-19 110-41.7 39.3-28.3 61.9-65 61.9-100.7V88c0-13.3-10.7-24-24-24zM99.3 192.8C74.9 175.2 64 155.6 64 144v-16h64.2c1 32.6 5.8 61.2 12.8 86.2-15.1-5.2-29.2-12.4-41.7-21.4zM512 144c0 16.1-17.7 36.1-35.3 48.8-12.5 9-26.7 16.2-41.8 21.4 7-25 11.8-53.6 12.8-86.2H512v16z',
		}
	},
	{
		name: 'Subway Stations',
		description: 'description',
		type: 'pharmacy',
		icon: 'fa-subway',
		marker: {
			path: 'M448 96v256c0 51.815-61.624 96-130.022 96l62.98 49.721C386.905 502.417 383.562 512 376 512H72c-7.578 0-10.892-9.594-4.957-14.279L130.022 448C61.82 448 0 403.954 0 352V96C0 42.981 64 0 128 0h192c65 0 128 42.981 128 96zM200 232V120c0-13.255-10.745-24-24-24H72c-13.255 0-24 10.745-24 24v112c0 13.255 10.745 24 24 24h104c13.255 0 24-10.745 24-24zm200 0V120c0-13.255-10.745-24-24-24H272c-13.255 0-24 10.745-24 24v112c0 13.255 10.745 24 24 24h104c13.255 0 24-10.745 24-24zm-48 56c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm-256 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z',
		}
	},
	{
		name: 'Synagogues',
		description: 'description',
		type: 'synagogue',
		icon: 'fa-synagogue',
		marker: {
			path: 'M70 196.51L6.67 268.29A26.643 26.643 0 0 0 0 285.93V512h128V239.58l-38-43.07c-5.31-6.01-14.69-6.01-20 0zm563.33 71.78L570 196.51c-5.31-6.02-14.69-6.02-20 0l-38 43.07V512h128V285.93c0-6.5-2.37-12.77-6.67-17.64zM339.99 7.01c-11.69-9.35-28.29-9.35-39.98 0l-128 102.4A32.005 32.005 0 0 0 160 134.4V512h96v-92.57c0-31.88 21.78-61.43 53.25-66.55C349.34 346.35 384 377.13 384 416v96h96V134.4c0-9.72-4.42-18.92-12.01-24.99l-128-102.4zm52.07 215.55c1.98 3.15-.29 7.24-4 7.24h-38.94L324 269.79c-1.85 2.95-6.15 2.95-8 0l-25.12-39.98h-38.94c-3.72 0-5.98-4.09-4-7.24l19.2-30.56-19.2-30.56c-1.98-3.15.29-7.24 4-7.24h38.94l25.12-40c1.85-2.95 6.15-2.95 8 0l25.12 39.98h38.95c3.71 0 5.98 4.09 4 7.24L372.87 192l19.19 30.56z',
		}
	},
	{
		name: 'Takeouts',
		description: 'description',
		type: 'meal_takeaway',
		icon: 'fa-burger-soda',
		marker: {
			path: 'M352 176v-32a16 16 0 0 0-16-16H206.74l20-80H272a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-51.5a40 40 0 0 0-38.81 30.3L157.26 128H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16zm-88.45 142.22a64.71 64.71 0 0 1 3.74-66.82c6.72-9.87 15.07-18.86 24.09-27.4H38.27l25.78 258.29A31.88 31.88 0 0 0 96 512h160a31.59 31.59 0 0 0 13.65-3.36A79.55 79.55 0 0 1 256 464a47.93 47.93 0 0 1 17-36.61 63.91 63.91 0 0 1 4.58-91.27 64 64 0 0 1-14.03-17.9zM624 448H304a16 16 0 0 0-16 16 48 48 0 0 0 48 48h256a48 48 0 0 0 48-48 16 16 0 0 0-16-16zm-16-96H320a32 32 0 0 0 0 64h288a32 32 0 0 0 0-64zm-314.26-82.59c-14.53 21.32.19 50.59 25.54 50.59h289.45c25.35 0 40-29.27 25.53-50.59C604.8 226.13 539.94 192.07 464 192s-140.79 34.13-170.26 77.41zM544 240a16 16 0 1 1-16 16 16 16 0 0 1 16-16zm-80-16a16 16 0 1 1-16 16 16 16 0 0 1 16-16zm-80 16a16 16 0 1 1-16 16 16 16 0 0 1 16-16z',
		}
	},
	{
		name: 'Taxi Stands',
		description: 'description',
		type: 'taxi_stand',
		icon: 'fa-taxi',
		marker: {
			path: 'M462 241.64l-22-84.84c-9.6-35.2-41.6-60.8-76.8-60.8H352V64c0-17.67-14.33-32-32-32H192c-17.67 0-32 14.33-32 32v32h-11.2c-35.2 0-67.2 25.6-76.8 60.8l-22 84.84C21.41 248.04 0 273.47 0 304v48c0 23.63 12.95 44.04 32 55.12V448c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-40.88c19.05-11.09 32-31.5 32-55.12v-48c0-30.53-21.41-55.96-50-62.36zM96 352c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm20.55-112l17.2-66.36c2.23-8.16 9.59-13.64 15.06-13.64h214.4c5.47 0 12.83 5.48 14.85 12.86L395.45 240h-278.9zM416 352c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z',
		}
	},
	{
		name: 'Train Stations',
		description: 'description',
		type: 'train_station',
		icon: 'fa-train',
		marker: {
			path: 'M448 96v256c0 51.815-61.624 96-130.022 96l62.98 49.721C386.905 502.417 383.562 512 376 512H72c-7.578 0-10.892-9.594-4.957-14.279L130.022 448C61.82 448 0 403.954 0 352V96C0 42.981 64 0 128 0h192c65 0 128 42.981 128 96zm-48 136V120c0-13.255-10.745-24-24-24H72c-13.255 0-24 10.745-24 24v112c0 13.255 10.745 24 24 24h304c13.255 0 24-10.745 24-24zm-176 64c-30.928 0-56 25.072-56 56s25.072 56 56 56 56-25.072 56-56-25.072-56-56-56z',
		}
	},
	{
		name: 'Travel Agencies',
		description: 'description',
		type: 'travel_agency',
		icon: 'fa-suitcase',
		marker: {
			path: 'M128 480h256V80c0-26.5-21.5-48-48-48H176c-26.5 0-48 21.5-48 48v400zm64-384h128v32H192V96zm320 80v256c0 26.5-21.5 48-48 48h-48V128h48c26.5 0 48 21.5 48 48zM96 480H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48h48v352z',
		}
	},
	{
		name: 'Universities',
		description: 'description',
		type: 'university',
		icon: 'fa-university',
		marker: {
			path: 'M496 128v16a8 8 0 0 1-8 8h-24v12c0 6.627-5.373 12-12 12H60c-6.627 0-12-5.373-12-12v-12H24a8 8 0 0 1-8-8v-16a8 8 0 0 1 4.941-7.392l232-88a7.996 7.996 0 0 1 6.118 0l232 88A8 8 0 0 1 496 128zm-24 304H40c-13.255 0-24 10.745-24 24v16a8 8 0 0 0 8 8h464a8 8 0 0 0 8-8v-16c0-13.255-10.745-24-24-24zM96 192v192H60c-6.627 0-12 5.373-12 12v20h416v-20c0-6.627-5.373-12-12-12h-36V192h-64v192h-64V192h-64v192h-64V192H96z',
		}
	},
	{
		name: 'Vets',
		description: 'description',
		type: 'veterinary_care',
		icon: 'fa-paw',
		marker: {
			path: 'M256 224c-79.41 0-192 122.76-192 200.25 0 34.9 26.81 55.75 71.74 55.75 48.84 0 81.09-25.08 120.26-25.08 39.51 0 71.85 25.08 120.26 25.08 44.93 0 71.74-20.85 71.74-55.75C448 346.76 335.41 224 256 224zm-147.28-12.61c-10.4-34.65-42.44-57.09-71.56-50.13-29.12 6.96-44.29 40.69-33.89 75.34 10.4 34.65 42.44 57.09 71.56 50.13 29.12-6.96 44.29-40.69 33.89-75.34zm84.72-20.78c30.94-8.14 46.42-49.94 34.58-93.36s-46.52-72.01-77.46-63.87-46.42 49.94-34.58 93.36c11.84 43.42 46.53 72.02 77.46 63.87zm281.39-29.34c-29.12-6.96-61.15 15.48-71.56 50.13-10.4 34.65 4.77 68.38 33.89 75.34 29.12 6.96 61.15-15.48 71.56-50.13 10.4-34.65-4.77-68.38-33.89-75.34zm-156.27 29.34c30.94 8.14 65.62-20.45 77.46-63.87 11.84-43.42-3.64-85.21-34.58-93.36s-65.62 20.45-77.46 63.87c-11.84 43.42 3.64 85.22 34.58 93.36z',
		}
	},
	{
		name: 'Zoos',
		description: 'description',
		type: 'zoo',
		icon: 'fa-elephant',
		marker: {
			path: 'M512 32c-13.16 0-25.52 2.66-37.4 6.4C464.71 15.82 442.23 0 416 0c-35.35 0-64 28.65-64 64 0 23.63 12.95 44.04 32 55.12V160c0 17.67 14.33 32 32 32h8c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8h-8c-35.29 0-64-28.71-64-64v-24.8c-20.08-18.03-32-43.92-32-71.2 0-11.28 2.31-21.94 5.9-32H192C85.96 32 0 117.96 0 224v112c0 8.84 7.16 16 16 16h16v144c0 8.84 7.16 16 16 16h64c8.84 0 16-7.16 16-16V388.15C160.35 405.7 198.72 416 240 416s79.65-10.3 112-27.85V496c0 8.84 7.16 16 16 16h64c8.84 0 16-7.16 16-16V288h64c23.44 0 45.11-6.76 64-17.75V368c0 8.83-7.17 16-16 16s-16-7.17-16-16c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16 0 46.87 40.52 84.46 88.36 79.57 41.62-4.25 71.64-42.46 71.64-84.3V160c0-70.69-57.31-128-128-128zm16 128c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16z',
		}
	}
]

/**
 * Foursquare API Request
 * @param  {Object} request
 * @return {Object}
 */
dataModel.foursquare = function(request) {

	/**
	 * Foursquare API URL
	 * @type {String}
	 * @see {@link https://developer.foursquare.com/docs/venues/search}
	 */
	const foursquareAPI = 'https://api.foursquare.com/v2/venues/search';

	return axios({
		method: 'GET',
		url: foursquareAPI,
		headers: {'content-type': 'application/x-www-form-urlencoded'},
		params: {
			ll: `${request.venueLat},${request.venueLng}`,
			query: request.venueName,
			intent: 'match',
			client_id: 'T3NSPSCOLUQ5R0OGEZCKUX0MOEUOEPW1HGFXYOF3ZKCYDQXD',
			client_secret: 'J2LN1WHKPT2MAQAP3POZP1REU2AWLYGM3S24B0DSLHZNHKJR',
			v: '20151230',
			m: 'foursquare'
		},
	});
}

/**
 * Uber API Request
 * @param  {Object} request
 * @return {Object}
 */
dataModel.uber = function(request) {

	/**
	 * Uber API URL
	 * @type {String}
	 * @see {@link https://developer.uber.com/v1/endpoints/}
	 */
	const uberAPI = 'https://api.uber.com/v1.2/estimates/price';
	const uberClientId = 'sF0uXRb14_xpSGdnNlfl8KgOVSgt6IXt';
	const uberServerToken = 'AKvxnyDLDHBO86RoOC0vcMNTByH1BRHDgerhfNmh';

	return axios({
		url: uberAPI,
		headers: {
			'Authorization': `Token ${uberServerToken}`,
			'content-type': 'application/x-www-form-urlencoded'
		},
		data: {
			start_latitude: request.startLat,
			start_longitude: request.startLng,
			end_latitude: request.endLat,
			end_longitude: request.endLng
		},
	});
}

/**
 * Get Local Storage Value
 * @param  {string} item Name of the item
 * @return {string}
 * @external 'localStorage.getItem()'
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Storage/getItem}
 */
dataModel.get = function(item) {
	return localStorage.getItem(item);
}

/**
 * Set Local Storage value
 * @param {string} item Name of the item
 * @param {string} value Value of the item
 * @external 'localStorage.setItem()'
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem}
 */
dataModel.set = function(item, value) {
	localStorage.setItem(item, value);
}

export default dataModel
